import './App.css';

import {useState} from 'react';


let accolades_text = [
  'Dr. Soni has given over one hundred fifty lectures on Intellectual Property Rights laws across India and abroad. He is a regular key-note speaker in the awareness camps on IPRs organised by the Department of Science and Technology, Govt. of Rajasthan. He has also given lectures in the seminars organised by the prestigious legal magazine of Asia Pacific “Asian Legal Business, Australia”, Singapore on the topics “Dispute Resolution in Intellectual Property Matters in India”, “Patent Laws in India” and “Corporate Governance in India”.',
  'The founder of Ashok Soni Associates, Dr. Ashok Soni, Senior Advocate, is a prolific writer having written twenty-two books (22) on various subjects of law including a reference book on Intellectual Property Rights.',
  'He has been one of the authors of the many prestigious publications like LexisNexis Butterworths.',
  'One of many lectures on IPR given by Dr.Soni. A step forward to disseminate law awareness regarding IPRs.',
]

let areas_of_practice_info = [
  'The area of practice of intellectual property rights cases is a complex one. In India,  apart from the five Chartered High Courts having original jurisdiction, the implementation of the legal right is a challenge especially in some of the States of India. Ashok Soni Associates provides a true class of proficient litigation services originating from the District Courts/Commercial Courts and in the appellate jurisdiction of the High Courts. With the abolition of the Intellectual Property Appellate Board (IPAB) by the Tribunal Reforms Act, 2021, the original and appellate jurisdiction in the High Courts requires special skill to protect the intellectual property rights.',
  'The banking and corporate laws have undergone a change in recent years keeping pace with global requirements such as implementation of the SARFAESI Act, 2002, the Companies Act, 2013 & Insolvency and Bankruptcy Code etc. Ashok Soni Associates commands specific knowledge to successfully handle the litigations of the field.',
  'The core arbitration law as an alternate dispute resolution mechanism has strengthened its roots in India since the implementation of the Arbitration and Conciliation Act, 1996. Ashok Soni Associates possesses the core efficiency to handle and prosecute the litigation of arbitration.',
  'The practice area of the civil litigation cannot be confined in the some of the fields yet Ashok Soni Associates is a front practitioner from the last three decades in the areas such as suit of partition, suit for recovery of due amount, suit for specific performance of contract, suit for possession and declaration, anti-suit injunction etc. The list is not exhaustive.',
]

function App() {
  let number_of_photos = 4;
  const [heading,setHeading] = useState('');
  const [img,setImg] = useState(1);
  const [area,setArea] = useState(0);
  function clickLogo(){
    const headerHeight = 0;
    const buffer = 100;
    let scrollToEl = document.getElementsByClassName('body')[0];
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
  function clickPracticeAreas(){
    const headerHeight = 50;
    const buffer = 25;
    let scrollToEl = document.getElementsByClassName('practice-areas-section')[0];
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
  function clickAboutUs(){
    const headerHeight = 50;
    const buffer = 25;
    let scrollToEl = document.getElementsByClassName('about-section')[0];
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  } 
  function clickContactUs(){
    const headerHeight = 50;
    const buffer = 25;
    let scrollToEl = document.getElementsByClassName('contact-us-section')[0];
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
  function clickAccolades(){
    const headerHeight = 50;
    const buffer = 25;
    let scrollToEl = document.getElementsByClassName('accolades-section')[0];
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
  function clickInfoSection(){
    const headerHeight = 50;
    const buffer = 70;
    let scrollToEl = document.getElementsByClassName('info-section')[0];
    // console.log(window.innerWidth)
    if(window.innerWidth>700){
      scrollToEl.style.display = 'grid';
    }
    else{
      scrollToEl.style.display = 'flex';
    }
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
  function handleLeftClick(){
    if(img === 1){
      setImg(number_of_photos);
      // x.style.animation-fill-mode = 'forwards';
    }
    else{
      setImg(img-1);
    }
    let x = document.getElementsByClassName('blackdrop')[0];
    let y = document.getElementsByClassName('accolades-info')[0];
    x.style.animationName = "none";
    y.style.animationName = "none";
    requestAnimationFrame(() => {
      setTimeout(() => {
        x.style.animationName = ""
        y.style.animationName = ""
      }, 0);
    });
  }
  function handleRightClick(){
    if(img === number_of_photos){
      setImg(1);
    }
    else{
      setImg(img+1);
    }
    let x = document.getElementsByClassName('blackdrop')[0];
    let y = document.getElementsByClassName('accolades-info')[0];
    x.style.animationName = "none";
    y.style.animationName = "none";
    requestAnimationFrame(() => {
      setTimeout(() => {
        x.style.animationName = ""
        y.style.animationName = ""
      }, 0);
    });
  }
  return (
    <div>
      <div className = 'navBar'>
        <div onClick = {clickLogo} className = 'logo-div'>
          <img className = 'image' src = {'/smol_logo.png'} alt = 'LOGO'/>
        </div>
        <div className = 'navTab' onClick = {clickAboutUs}>
          ABOUT US
        </div>
        <div className = 'navTab' onClick = {clickPracticeAreas}>
          PRACTICE AREAS
        </div>
        <div className = 'navTab' onClick = {clickAccolades}>
          RECOGNITION AND ACCOLADES
        </div>
        <div className = 'navTab' onClick = {clickContactUs}>
          CONTACT US
        </div>
      </div>
      <div className = 'body'>
        <img className = 'logo' src = {'/smol_logo_4.png'} alt = {`Logo`}/>
        {/* <div className = "title">
          Ashok Soni Associates
        </div>
        <div className = 'title-text'>
          Senior Advocate : Registered Patent and Trademark Attorney
        </div> */}
        {/* <div className = 'title-name'>
          {`Ashok Soni Associates`}
        </div> */}
        <div className= 'about-section'>
        <div className='ashokji_photo'>
          <img src = {'/ashokji_photo.png'} alt = {`Dr.Soni`}/>
        </div>
          <div className = 'about-us-heading'>
            ABOUT US
          </div>
          <div className = 'about-us-body'>
            Ashok Soni Associates founded by Dr. Ashok Soni in the year 1991 to provide truely world class legal services. He is designated as a Senior Advocate by the Rajasthan High Court, Jodhpur in the year 2022. Dr. Soni is a Registered Patents and Trade Marks Attorney. Leading from the front, he is supported by an experienced team of lawyers. The core areas of practice of Ashok Soni Associates are Intellectual Property Rights litigation, Banking and Corporate Laws, Arbitration and the entire range of civil litigation. Ashok Soni Associates covers all the jurisdictions accross India.
          </div>
        </div>
        <div className='practice-areas-section'>
          <div className = 'practice-areas-heading'>
            PRACTICE AREAS
          </div>
          <div className = 'practice-areas-body'>
            <span className = 'prac' onClick = {()=>{setHeading('INTELLECTUAL PROPERTY RIGHTS'); setArea(1);clickInfoSection();}}>
              <div className = 'prac-name'>
                Intellectual Property Rights
              </div>
            </span>
            <span className = 'prac' onClick = {()=>{setHeading('BANKING AND CORPORATE LAWS'); setArea(2);clickInfoSection();}}>
              <div className = 'prac-name'>
                Banking and Corporate Laws
              </div>
            </span>
            <span className = 'prac' onClick = {()=>{setHeading('ARBITRATION LAW');setArea(3); clickInfoSection();}}>
              <div className = 'prac-name'>
                Arbitration Law
              </div>
            </span>
            <span className = 'prac' onClick = {()=>{setHeading('RESIDUE CIVIL LITIGATION');setArea(4); clickInfoSection();}}>
              <div className = 'prac-name'>
                Residue Civil Litigation
              </div>
            </span>
          </div>
        </div>
        <div className = 'info-section'>
          <div className = 'info-heading'>
            {heading}
          </div>
          <div className = 'info-body'>
            {areas_of_practice_info[area-1]}
          </div>
        </div>
        <div className = 'accolades-section'>
          <div className = 'accolades-heading'>
            RECOGNITION AND ACCOLADES
          </div>
          <div className = 'accolades-body'>
            <button className='button leftbtn' onClick={handleLeftClick}>{`<`}</button>
            <div className='accolades-img'>
              <img src = {`/accolades_img_${img}.JPG`} alt = {`accolades_img${img}`}>
              </img>
              <div className='accolades-info'>
                {accolades_text[img-1]}
              </div>
              <div className='blackdrop'/>
            </div>
            <button className='button rightbtn' onClick={handleRightClick}>{`>`}</button>
          </div>
        </div>
        <div className = 'contact-us-section'>
          <div className = 'contact-us-heading'>
            CONTACT US
          </div>
          <div className = "contact-us-body">
            <div className = 'heading1'>
              Address
            </div>
            <div className = 'body1'>
              A-118, Shastri Nagar, Opposite Gate no. 2 Shri Vishwakarma Public School, Jodhpur-342003, India
            </div>
            <div className = 'heading1'>
              Email
            </div>
            <div className = 'body1'>
              advashoksoni@gmail.com
            </div>
            <div className = 'heading1'>
              Contact Number
            </div>
            <div className = 'body1'>
              +91 9828031619
            </div>
            <div className = 'body1'>
              +91 9928861619
            </div>
            <div className = 'body1 last'>
              +91 8905936199
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
